import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const StatboxWrapper = styled.div`
	width: 100%;
	background-color: ${setColors.warmGray};
`;
export const StatTitle = styled.h3`
	margin: 0 15px;
	padding: 85px 0 20px 0;
	font-family: ${setFontFamily.book};
	color: ${setColors.lightBlack};
	font-size: ${setFontSize.mediumSmall};
	line-height: 1.2;
	font-weight: ${setFontWeight.bold};
	font-stretch: normal;
	font-style: normal;
	text-align: center;
	${mediaQuery.tabletL`
		padding: 60px 0 25px 0;
		font-size: ${setFontSize.tinySmall};
	`}
	${mediaQuery.mobileXL`
		padding: 30px 0 40px 0;
		font-size: ${setFontSize.mediumTiny};
	`}
`;
export const Stats = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding-bottom: 100px;
	${mediaQuery.tabletL`
		flex-direction: column;
		padding-bottom: 60px;
	`}
	${mediaQuery.mobileXL`
		padding-bottom: 30px;
	`}
`;
export const Stat = styled.div`
	text-align: center;
	margin: 0 30px;
`;
export const Metric = styled.div`
	font-family: ${setFontFamily.jost_medium};
	font-size: ${setFontSize.largerLarge};
	${mediaQuery.laptopM`
		font-size: ${setFontSize.extraLarge};
	`}
	${mediaQuery.tabletL`
		font-size: ${setFontSize.large50};
	`}
	${mediaQuery.mobileXL`
		font-size: ${setFontSize.larg60};
	`}
`;
export const MetricDescription = styled.div`
	font-size: ${setFontSize.verySmall};
	margin-top: 10px;
	${mediaQuery.tabletL`
		font-size: ${setFontSize.verySmall};
		margin-top: 5px;
		margin-bottom: 30px;
	`}
`;
