import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/global/seo';
import Layout from '../components/global/layout/Layout';
import InfoBox from '../components/global/info-box/InfoBox';
import FeaturedCustomers from '../components/home-page/featured-customers/FeaturedCustomers';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import StatsBox from '../components/global/stats-box/StatsBox';
import CTA from '../components/global/CTA/CTA';
import VerticalSlider from '../components/global/ImageSlider/VerticalSlider';
import { Container } from '../components/global/customers/styles';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import Snippet from '../components/global/snippet/Snippet';
import { sliderProps } from '../pages/bot-builder';
import * as S from '../components/demand-generation/styles';
import { SectionTitle } from '../components/bot-builder-page/styles';
import { ImageHolder } from '../components/home-page/about-chatbots/styles';
import {
	SectionWrapper,
	SliderWrapper,
	SliderContainer,
	SnippetWrapper,
} from '../components/customer-support/styles';
const DemandGeneration = ({ data }) => {
	const {
		seoTitle,
		seoImage,
		seoDescription,
		seoArticle,
		section1Image,
		section1Button,
		section2Stats,
		section3Image,
		section4Title,
		section4Features,
		section5Image,
		section6Title,
		section7Image,
		section8Title,
		section8Images,
		section9Image,
		ctaTitle,
		seoJsonSchema,
	} = data.contentfulCustomerSupport;
	return (
		<>
			<Layout>
				<SEO
					title={seoTitle}
					description={seoDescription}
					image={seoImage}
					article={seoArticle}
					seoJsonSchema={seoJsonSchema}
				/>
				<WrapperOne>
					<S.HeroWrapper>
						<InfoBox
							image={section1Image}
							title={section1Image.title}
							subtitle={section1Image.description}
							hideImage="true"
							buttonText={section1Button?.linkText}
							buttonLink={section1Button?.link}
							subHeading
							videoBorderRadius={'10px'}
							isHeroHeader
						/>
					</S.HeroWrapper>
				</WrapperOne>
				<S.StatsWrapper>
					<StatsBox data={section2Stats.name} title={section2Stats.title} />
				</S.StatsWrapper>
				<Container>
					<SectionWrapper>
						<InfoBox
							image={section3Image}
							title={section3Image.title}
							subtitle={section3Image.description}
							invert="true"
							subHeading="true"
							videoBorderRadius={'10px'}
						/>
					</SectionWrapper>
					<SliderContainer>
						<SectionTitle className="font-black-54">
							{section4Title}
						</SectionTitle>
						<SliderWrapper>
							<VerticalSlider data={section4Features} {...sliderProps} />
						</SliderWrapper>
					</SliderContainer>
					<SectionWrapper style={{ marginTop: '-70px', marginBottom: '55px' }}>
						<InfoBox
							image={section5Image}
							title={section5Image.title}
							subtitle={section5Image.description}
							alignLeft="true"
							subHeading="true"
							videoBorderRadius={'10px'}
						/>
					</SectionWrapper>
					<FeaturedCustomers
						section10Title={section6Title}
						customers={data.allContentfulCustomers.nodes}
					/>
					<SectionWrapper>
						<InfoBox
							image={section7Image}
							title={section7Image.title}
							subtitle={section7Image.description}
							invert="true"
							subHeading="true"
							videoBorderRadius={'10px'}
						/>
					</SectionWrapper>
					<SnippetWrapper>
						<SectionTextBox title={section8Title} />
						<div style={{ padding: '10px' }}></div>
						<ImageHolder>
							{section8Images.map(info => (
								<Snippet
									alt={info.title}
									url={info.file.url}
									title={info.title}
									subtitle={info.description}
									key={info.title}
								/>
							))}
						</ImageHolder>
					</SnippetWrapper>
					<SectionWrapper>
						<InfoBox
							image={section9Image}
							title={section9Image.title}
							subtitle={section9Image.description}
							subHeading="true"
							videoBorderRadius={'10px'}
						/>
					</SectionWrapper>
				</Container>
				<S.CTAWrapper>
					<Container>
						<CTA title={ctaTitle} />
					</Container>
				</S.CTAWrapper>
			</Layout>
		</>
	);
};

export const query = graphql`
	query($relatedCustomerSlug: [String]) {
		contentfulCustomerSupport {
			seoTitle
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoDescription
			seoArticle
			section1Image {
				title
				description
				file {
					url
					contentType
				}
			}
			seoJsonSchema {
				internal {
					content
				}
			}
			section1Button {
				link
				linkText
			}
			section2Stats {
				title
				name {
					content
					title
				}
			}
			section3Image {
				title
				description
				file {
					url
					contentType
				}
			}
			section4Title
			section4Features {
				title
				description
				fluid(maxHeight: 500, maxWidth: 747) {
					...GatsbyContentfulFluid
				}
			}
			section5Image {
				title
				description
				file {
					url
					contentType
				}
			}
			section6Title
			section7Image {
				title
				description
				file {
					url
					contentType
				}
			}
			section8Title
			section8Images {
				file {
					url
				}
				title
				description
			}
			section9Image {
				title
				description
				file {
					url
					contentType
				}
			}
			ctaTitle
		}
		allContentfulCustomers(
			filter: { slug: { in: $relatedCustomerSlug } }
			sort: { order: ASC, fields: id }
		) {
			nodes {
				title
				slug
				cardImage {
					fluid(maxWidth: 234, maxHeight: 234) {
						...GatsbyContentfulFluid
					}
					title
				}
				cardLogo {
					fixed(width: 200) {
						...GatsbyContentfulFixed
					}
					title
				}
			}
		}
	}
`;

export default DemandGeneration;
