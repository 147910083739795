import React from 'react';
import * as S from './styles';

const StatsBox = ({ data, title }) => {
	return (
		<S.StatboxWrapper>
			<S.StatTitle dangerouslySetInnerHTML={{ __html: title }}></S.StatTitle>
			<S.Stats>
				{data.map((stat, i) => (
					<S.Stat key={i}>
						<S.Metric>{stat.title}</S.Metric>
						<S.MetricDescription>{stat.content}</S.MetricDescription>
					</S.Stat>
				))}
			</S.Stats>
		</S.StatboxWrapper>
	);
};

export default StatsBox;
