import styled from 'styled-components';
import { mediaQuery } from '../../../../utils/helpers';

export const Wrapper = styled.div`
	margin-top: 127px;
	margin-bottom: 60px;
	${mediaQuery.mobileXL`
		margin-top: 45px;
		margin-bottom: 30px;
	`}
`;

export const CustomerHolder = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	align-items: center;
	justify-content: center;

	${mediaQuery.mobileXL`
		flex-direction: column;
		max-width: 100%;
	`}
`;

export const CustomerWrapper = styled.div`
	margin: 0 15px !important;
	${mediaQuery.mobileXL`
	margin: 0 15px 30px 15px !important;
	`}
`;
