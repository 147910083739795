import React from 'react';
import CustomerSnippet from '../../global/customer-snippet/CustomerSnippet';
import Title from '../../global/title/Title';
import { TitleWrapper } from '../../customers-page/customer-holder/styles';
import * as S from './styles';

const FeaturedCustomers = ({ section10Title, customers }) => {
	return (
		<S.Wrapper>
			<TitleWrapper>
				<Title title={section10Title} />
			</TitleWrapper>
			<S.CustomerHolder>
				{customers.map(customer => (
					<S.CustomerWrapper key={customer.title}>
						<CustomerSnippet
							image={customer.cardImage}
							logo={customer.cardLogo}
							title={customer.title}
							slug={`customers/${customer.slug}`}
						/>
					</S.CustomerWrapper>
				))}
			</S.CustomerHolder>
		</S.Wrapper>
	);
};

export default FeaturedCustomers;
